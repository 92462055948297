import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Starlit Adventures
			</title>
			<meta name={"description"} content={"Join Bo and Kikki in their quest to retrieve the stars stolen by Nuru!"} />
			<meta property={"og:title"} content={"Starlit Adventures"} />
			<meta property={"og:description"} content={"Join Bo and Kikki in their quest to retrieve the stars stolen by Nuru!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66fd2fa928b5e30023ad014b/images/DALL_E_2024-10-02_14.44.05_-_A_stylish_and_minimalist_favicon_design_for_the_mobile_game__Starlit_Adventures_._The_favicon_features_a_simplified_version_of_the_game_s_logo_or_an_i-removebg-preview.png?v=2024-10-02T12:08:22.583Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});